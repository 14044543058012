<template>
  <div class="home">
    <div class="home-wrap">
      <div class="menu-item" 
        v-if="menuPermission.Project.ProjectList"
        @click="goto('ProjectList')">
        <img src="/icon/01.png" width="90px">
        <p class="chn">项目综合</p>
        <p class="eng">Project synthesis</p>
      </div>

      <div class="menu-item"
        v-if="menuPermission.Organization.Unit || menuPermission.Organization.Personnel || menuPermission.Organization.Department"
        @click="goto('Unit')">
        <img src="/icon/02.png" width="90px">
        <p class="chn">参建单位管理</p>
        <p class="eng">Management of participating units</p>
      </div>

      <div class="menu-item" 
        v-if="menuPermission.Attendance.AttendanceConfig || menuPermission.Attendance.AttendancePerson"
        @click="goto('AttendancePerson')">
        <img src="/icon/03.png" width="90px">
        <p class="chn">考勤管理</p>
        <p class="eng">Attendance management</p>
      </div>

      <div class="menu-item" 
        v-if="menuPermission.Device.DischargingPlatform || menuPermission.Device.DustMonitoring || menuPermission.Device.ElectricityMonitoring || menuPermission.Device.Elevator || menuPermission.Device.IntelligenceEye || menuPermission.Device.TowerCrane"
        @click="goto('TowerCrane')">
        <img src="/icon/04.png" width="90px">
        <p class="chn">设备管理</p>
        <p class="eng">Device management</p>
      </div>

      <div class="menu-item" 
        v-if="menuPermission.Video.VideoList"
        @click="goto('VideoList')">
        <img src="/icon/06.png" width="90px">
        <p class="chn">视频管理</p>
        <p class="eng">Video management</p>
      </div>

      <div class="menu-item" 
        v-if="menuPermission.System.MyConfig || menuPermission.System.SysUser"
        @click="goto('MyConfig')">
        <img src="/icon/05.png" width="90px">
        <p class="chn">系统设置</p>
        <p class="eng">System settings</p>
      </div>

    </div>
  </div>
</template>

<style lang="less">
.home-wrap {
  width: 1200px;
  margin: 0 auto;
  // border: 2px solid #fff;
  // border-radius: 10px;
  // padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.menu-item {
  margin-right: 60px;
  margin-top: 60px;
  width: 360px;
  height: 250px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.menu-item:hover {
  box-shadow: 0px 0px 20px rgba(64, 158, 255, 0.5);
  transform: scale(1.1);
}
.menu-item:nth-child(3n+0) {
  margin-right: 0;
}
.chn {
  margin-top: 30px;
  font-size: 22px;
  font-weight: bold;
  color: #409EFF;
}
.eng{
  font-size: 16px;
  font-weight: bold;
  color: #409EFF;
}
</style>

<script>

export default {
  name: "Home",
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    menuPermission: function () {
      return JSON.parse(localStorage.getItem("menuPermission"));
    },
  },
  mounted() {
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path
      })
    }
  },
};
</script>
